import APIService from "./APIService";

export default {
  getDadosIndicadoresReclamacoes(
    competenciaDe,
    competenciaAte,
    inspecoesSelecionadas,
  ) {
    const inspecoes = encodeURI(inspecoesSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-reclamacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&inspecoes=${inspecoes}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresReclamacoesMensais(
    competenciaDe,
    competenciaAte,
    inspecoesSelecionadas,
  ) {
    const inspecoes = encodeURI(inspecoesSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-reclamacoes-mensais?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&inspecoes=${inspecoes}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getIndicadoresReclamacaoMensaisTodasEmpresas(
    competenciaDe,
    competenciaAte,
    inspecoesSelecionadas,
  ) {
    const inspecoes = encodeURI(inspecoesSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-reclamacoes-mensais-todas-empresas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&inspecoes=${inspecoes}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getDadosIndicadoresTecnicos(
    competenciaDe,
    competenciaAte,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas
  ) {
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-tecnicos?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresTecnicosMensais(
    competenciaDe,
    competenciaAte,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas
  ) {
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-tecnicos-mensais?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getIndicadoresTecnicosMensaisTodasEmpresas(
    competenciaDe,
    competenciaAte,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas
  ) {
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-tecnicos-mensais-todas-empresas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getDadosIndicadoresServicos(
    competenciaDe,
    competenciaAte
  ) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-servicos?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresServicosMensais(
    competenciaDe,
    competenciaAte
  ) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-servicos-mensais?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getIndicadoresServicosMensaisTodasEmpresas(
    competenciaDe,
    competenciaAte
  ) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-servicos-mensais-todas-empresas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },

  getDadosIndicadoresPorMunicipio(
    competenciaDe,
    competenciaAte,
    regionalId,
    inspecoesSelecionadas,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas
  ) {
    const inspecoes = encodeURI(inspecoesSelecionadas);
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-por-municipio?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&inspecoes=${inspecoes}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresMensais(
    competenciaDe,
    competenciaAte,
    regionalId,
    municipioSelecionado,
    inspecoesSelecionadas,
    medicoesSelecionadas,
    origemMedicoesSelecionadas,
    tensaoReferenciaSelecionadas
  ) {
    const municipio = municipioSelecionado ? encodeURI(municipioSelecionado) : "";
    const inspecoes = encodeURI(inspecoesSelecionadas);
    const medicoes = encodeURI(medicoesSelecionadas);
    const origemMedicoes = encodeURI(origemMedicoesSelecionadas);
    const tensaoReferencia = encodeURI(tensaoReferenciaSelecionadas);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-nivel-tensao/get-dados-indicadores-mensais?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&municipio_selecionado=${municipio}&inspecoes=${inspecoes}&medicoes=${medicoes}&origem_medicoes=${origemMedicoes}&tensao_referencia=${tensaoReferencia}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
};
